import React, { useEffect, useState } from "react";
import { TextField, MenuItem, CircularProgress } from "@mui/material";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import cogoToast from 'cogo-toast';
import { postCall } from "../../../Api/axios";

const GenerateNamePopupFields = ({ state, statehandler }) => {
    let myBucket = process.env.REACT_APP_S3_BUCKET;
    let regionString = '-' + process.env.REACT_APP_S3_REGION;
    const baseUrl = `https://${myBucket}.s3${regionString}.amazonaws.com/`;
    const API_URL = "/api/v1/llm/getProductTitle";

    console.log("[GenerateNamePopupFields] state: ", state);
    let prompt = "Generate 10 unique and captivating product titles for this " + state.productSubcategory1 + ". Each title should be imaginative, descriptive, concise, easy-to-understand, and distinct from one another, reflecting the design's intricate details.";

    const models = [
        "gemini-2.0-flash-thinking-exp",
        "gemini-2.0-flash-exp",
        "gemini-1.5-flash"
    ];
    const [currentModel, setCurrentModel] = useState("gemini-2.0-flash-exp");
    const [productTitles, setProductTitles] = useState([]);
    const [currentProductName, setCurrentProductName] = useState("Choose a name for your product");
    const [loading, setLoading] = useState(false);
    const [userPrompt, setUserPrompt] = useState(prompt);
    const validExtensions = ["jpg", "jpeg", "png", "webp", "heic", "heif"];
    let imageUrl = "";
    if (state.images && state.images.length > 0) {
        for (let i = 0; i < state.images.length; i++) {
            const ext = state.images[i].split(".").pop();
            if (validExtensions.includes(ext)) {
                imageUrl = baseUrl + state.images[i];
                // todo: check whether the imageUrl is valid or not. assess whether we need to implement this
                break;
            }
        }
    }

    if (imageUrl === "") {
        cogoToast.error("No valid image found to generate product titles!");
        console.error("[GenerateNamePopupFields] No valid image found to generate product titles! validExtensions: ", validExtensions, " state.images: ", state.images);
    }

    const generateProductTitle = async () => {
        try {
            console.log("[GenerateNamePopupFields] generateProductTitle imageUrl: ", imageUrl);
            if (imageUrl === "") {
                return;
            }
            setLoading(true);
            const data = {
                "model": currentModel,
                "userPrompt": userPrompt,
                "imageUrl": imageUrl
            };
            console.log("[GenerateNamePopupFields] generateProductTitle data: ", data);
            const response = await postCall(API_URL, data);
            console.log("[GenerateNamePopupFields] generateProductTitle response: ", response);
            cogoToast.success("Product titles generated successfully!");
            setLoading(false);
            setProductTitles(response.productTitles);
        }
        catch (error) {
            console.error("[GenerateNamePopupFields] generateProductTitle error: ", error);
            setLoading(false);
            cogoToast.error("Failed to generate product titles! see console for more info");
        }  
    };
    function capitalizeFirstLetter(val) {
        return String(val).charAt(0).toUpperCase() + String(val).slice(1);
    }
    function updateProductNameFromTitle(title) {
        let fullName = title || state.productName;
        fullName+=` (Gross: ${parseFloat(state.weight).toFixed(2)}g | Net: ${parseFloat(state.net_weight).toFixed(2)}g) - ${state.Purity}KT BIS ${state.Purity=="22"?"916 ":""}Hallmark ${capitalizeFirstLetter(state.Finishing)} Gold`

        let id = "outlined-multiline-static1";
        let element = document.getElementById(id);
        setCurrentProductName(title);
        statehandler({ ...state, productName: fullName, productTitle: title });
        if (element) {
            element.value = title;
        }
    }

    useEffect(() => {
        generateProductTitle();
    }, []);

    useEffect(() => {
        updateProductNameFromTitle(state.productTitle);
    }, [state.productTitle]);
  return (
    <div className="md:w-[24rem] mx-[1rem]">
        <img src= {imageUrl} alt="Product" className="w-[24rem] h-[24rem]"
         style={{marginTop: "7px"}} />
        <details>
            <summary>Advanced Settings</summary>
            <div>
                {/* select to choose model */}

                <TextField
                    id="outlined-select-currency"
                    select
                    label="Model"
                    value={currentModel}
                    onChange={(e) => setCurrentModel(e.target.value)}
                    helperText="Please select a model"
                    variant="outlined"
                    fullWidth
                    style={{marginTop: "1rem"}}
                >
                    {models.map((option) => (
                        <MenuItem key={option} value={option} style={{justifyContent: "left", paddingLeft: "1rem"}}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>

                {/* textarea */}
                <TextField
                    id="outlined-multiline-static"
                    label="Prompt"
                    multiline   
                    onChange={(e) => setUserPrompt(e.target.value)}
                    rows={4}
                    defaultValue={prompt}
                    variant="outlined"
                    fullWidth
                    style={{marginTop: "1rem"}}
                />
                <button 
                    type="button"
                    className="bg-blue-500 hover:bg-blue-700 mx-[1rem] text-white font-bold py-2 px-4 rounded mt-2"
                    onClick={()=>{
                        generateProductTitle();
                    }}
                >
                    <AutoFixHighIcon />
                    Generate
                </button>
            </div>
        </details>
        {loading ? 
        <div className="md:w-[24rem] mx-[1rem] flex justify-center">
            <CircularProgress /> 
        </div>
            : 
        <div className="md:w-[24rem] mx-[1rem]">
            <h2 style={{marginTop: "1rem", fontWeight: "bold"}}>
                Choose a name for your product
            </h2>
            {productTitles.map((title, index) => (
                <div className="flex items-center" key={index}>
                    <input type="radio" id={index} name="productName" className="mr-2" onChange={() => 
                        {
                            statehandler({ ...state, productTitle: title })
                            updateProductNameFromTitle(title);
                        }
                    } />
                    <label for={index} className="text-lg">
                        {title}
                    </label>
                </div>
            ))}
            {/* the changes will be reflected in this text area field  */}
            <TextField
                id="outlined-multiline-static1"
                label="Product Name"
                multiline   
                onChange={(e) => {
                    updateProductNameFromTitle(e.target.value);
                }
                }
                rows={4}
                defaultValue={"Choose a name for your product"}
                variant="outlined"
                fullWidth
                style={{marginTop: "1rem"}}
            />
        </div>}
    </div>
    );
};

export default GenerateNamePopupFields;
