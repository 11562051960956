import React, { useEffect, useRef, useState } from "react";
import cogoToast from "cogo-toast";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  IconButton,
} from "@material-ui/core";
import {Checkbox} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import DeleteIcon from "@mui/icons-material/Delete";
import RenderInput from "../../../utils/RenderInput";
import GemstoneField from "./gemstonefields";
import GenerateNamePopupFields from "./GenerateNamePopup";
import GenerateDescPopupFields from "./GenerateDescPopup";
import { gemStone } from "./product-fields-modified";
import {
  PRODUCT_SUBCATEGORY,
  FIELD_NOT_ALLOWED_BASED_ON_PROTOCOL_KEY,
} from "../../../utils/constants";
import { allProductFieldDetails } from "./product-fields";
import { getCall } from "../../../Api/axios";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

const AddProductInfo = ({
  allFields,
  fields,
  state,
  form,
  setFocusedField,
  variatonOn,
  duplicateProductCode,
  handleDuplicateProductCode,
  isAdmin
}) => {
  console.log("AddProductInfo fields : ", allFields, fields, state, form, setFocusedField);
  const { formValues, setFormValues, errors, setErrors } = form;
  const [showDialog, setShowDialog] = useState(false);
  const [showGenerateNameDialog, setShowGenerateNameDialog] = useState(false);
  const [showGenerateDescDialog, setShowGenerateDescDialog] = useState(false);
  const [newGemstone, setNewGemstone] = useState({
    gemstoneType: "",
    gemstoneSize: "",
    gemstoneCount: "",
    gemstoneCostPerStone: "",
  });
  const [autofillChecked, setAutoFillChecked] = useState(false);
  const theme = useTheme();

  useEffect(()=>{
    if(formValues.productName!==formValues.description)
      setAutoFillChecked(false)
  }, [formValues.description]);

  //update desc when changes in name occurs
  useEffect(()=>{
    if(autofillChecked)
      setFormValues({
        ...formValues,
        description: formValues.productName
      })
  }, [formValues.productName]);

  const getProductFieldDetails = (category_id) => {
    return allFields.find((field) => field.id === category_id);
  };
  const handleGemstoneChange = (updatedGemstone) => {
    // Update the local state for the current gemstone
    setNewGemstone((prevGemstone) => ({
      ...prevGemstone,
      ...updatedGemstone,
    }));
  };

  const handleDialogSubmit = () => {
    // Add the gemstone to the form state only if it has valid values
    if (
      newGemstone.gemstoneType &&
      newGemstone.gemstoneCount &&
      newGemstone.gemstoneCostPerStone
    ) {
      // Update the gemstone values in the gemstone array in the parent component
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        gemstones: [...prevFormValues.gemstones, newGemstone],
      }));

      setNewGemstone({
        gemstoneType: "",
        gemstoneSize: "",
        gemstoneCount: "",
        gemstoneCostPerStone: "",
      });

      setShowDialog(false);
    } else {
      cogoToast.error("Please fill out all the required fields");
    }
  };

  const handleDialogCancel = () => {
    setShowDialog(false);
  };
  const handleGenerateNameDialogCancel = () => {
    setShowGenerateNameDialog(false);
  };
  const handleGenerateDescDialogCancel = () => {
    setShowGenerateDescDialog(false);
  };
  const handleGemstoneDelete = (index) => {
    const updatedGemstones = [...formValues.gemstones];
    updatedGemstones.splice(index, 1);
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      gemstones: updatedGemstones,
    }));
  };
  let priceHeadingBool = 1;

  return (
    <>
    <h1 className="py-2 text-2xl font-semibold">Basic Product Details:</h1>
      {fields?.map((category_id, index) => {
        let item = getProductFieldDetails(category_id);
        
        let returnElement = true;

        if (formValues?.productSubcategory1) {
          const subCatList = PRODUCT_SUBCATEGORY[formValues?.productCategory];
          const selectedSubCatObject = subCatList?.find(
            (subitem) => subitem.value === formValues?.productSubcategory1
          );
          if (selectedSubCatObject && selectedSubCatObject.protocolKey) {
            const hiddenFields =
              FIELD_NOT_ALLOWED_BASED_ON_PROTOCOL_KEY[
                selectedSubCatObject.protocolKey
              ];
            const fielditemAvailableInHidden = hiddenFields.find(
              (hiddenItem) => hiddenItem === item.id
            );
            if (fielditemAvailableInHidden) {
              returnElement = false;
            }
          }
        } else {
        }

        if (returnElement) {
          
          if (item.id === "productCode") {
            item.handleBlur = async() => {
              const checkUrl = `/api/v1/checkProductCode?productCode=${formValues.productCode}`;
              const res = await getCall(checkUrl);
              if(res?.duplicateProductCode === true){
                handleDuplicateProductCode(true);
              } else {
                handleDuplicateProductCode(false);
              }
            }
            return (
              <>
                <RenderInput
                  key={item.id}
                  previewOnly={
                    state?.productId && item.id === "productCode" ? true : false
                  }
                  item={{
                    ...item,
                    error: errors?.[item.id] ? true : false,
                    helperText: errors?.[item.id] || "",
                  }}
                  state={formValues}
                  stateHandler={setFormValues}
                  setFocusedField={setFocusedField}
                />
                {duplicateProductCode && !errors.productCode &&
                  <p className="ml-2 mb-2 text-xs text-yellow-500 cursor-default">
                    Warning: A product with the same number already exists!
                  </p>}
              </>
            );
          }
          // info: remove the isAdmin to allow the productName to be generated by sellers
          if (item.id === "productName" && isAdmin) {
            return (
              <>  
                <RenderInput
                  key={item.id}
                  previewOnly={
                    state?.productId && item.id === "productCode" ? true : false
                  }
                  item={{
                    ...item,
                    error: errors?.[item.id] ? true : false,
                    helperText: errors?.[item.id] || "",
                  }}
                  state={formValues}
                  stateHandler={setFormValues}
                  setFocusedField={setFocusedField}
                />

                <button 
                  type="button"
                  className="border border-black px-3 py-[7px] mt-2 rounded-xl bg-[#ebe4dc]"
                  onClick={()=>{
                    setShowGenerateNameDialog(true);
                  }}
                >
                  <AutoFixHighIcon />
                  &nbsp;
                  Generate Name
                </button>
              </>
            );
          }
          

          if (item.id === "images") {
            return (<>
              <RenderInput
                key={item.id}
                previewOnly={
                  state?.productId && item.id === "productCode" ? true : false
                }
                item={{
                  ...item,
                  error: errors?.[item.id] ? true : false,
                  helperText: errors?.[item.id] || "",
                }}
                state={formValues}
                stateHandler={setFormValues}
                setFocusedField={setFocusedField}
              />
            </>
            )
          } 
          
          if (item.id === "diamonds_weight") {
            return (<>
              <label className="text-2xl font-semibold mb-4 mt-14">Diamond Details:</label>
              <RenderInput
                key={item.id}
                previewOnly={
                  state?.productId && item.id === "productCode" ? true : false
                }
                item={{
                  ...item,
                  error: errors?.[item.id] ? true : false,
                  helperText: errors?.[item.id] || "",
                }}
                state={formValues}
                stateHandler={setFormValues}
                setFocusedField={setFocusedField}
              />
            </>
            )
          }
          
          if (priceHeadingBool && (item.id === "wastageOnGold" || item.id === "diamondCostPerCt")){
            priceHeadingBool = 0;
            return (<>
              <label className="text-2xl font-semibold mb-4 mt-14">Pricing:</label>
              <RenderInput
                key={item.id}
                previewOnly={
                  state?.productId && item.id === "productCode" ? true : false
                }
                item={{
                  ...item,
                  error: errors?.[item.id] ? true : false,
                  helperText: errors?.[item.id] || "",
                }}
                state={formValues}
                stateHandler={setFormValues}
                setFocusedField={setFocusedField}
              />
            </>
            )
          }

          // info: remove the isAdmin to allow the productName to be generated by sellers
          if (item.id==="description" && isAdmin) {
            return (<>
              <RenderInput
                key={item.id}
                previewOnly={
                  state?.productId && item.id === "productCode" ? true : false
                }
                item={{
                  ...item,
                  error: errors?.[item.id] ? true : false,
                  helperText: errors?.[item.id] || "",
                }}
                state={formValues}
                stateHandler={setFormValues}
                setFocusedField={setFocusedField}
              />
              <button 
                type="button"
                className="border border-black px-3 py-[7px] mt-2 rounded-xl bg-[#ebe4dc]"
                onClick={()=>{
                  setShowGenerateDescDialog(true);
                }}
              >
                <AutoFixHighIcon />
                &nbsp;
                Generate Description
              </button>
                {/* <Checkbox
                  type="checkbox"
                  id="copy-checkbox"
                  className="!scale-75"
                  checked={autofillChecked}
                  onChange={(e)=>{
                    if(e.target.checked)
                      setFormValues({
                        ...formValues,
                        description: formValues.productName
                      })
                    setAutoFillChecked(e.target.checked)
                  }}
                  >
                </Checkbox>
                <label htmlFor="copy-checkbox" className="text-xs py-2 font-medium text-left text-[#606161]">AutoFill from Product Name?</label> */}
              {/* {autofillChecked && <p className="mt-[-8px] font-serif text-sm text-gray-400 cursor-default">
                  &gt;&gt;&gt;We'll take care of this, for now ;&#41;</p>} */}
            </>
            )
          }

          if(item.id === "gemstonePrice") {
            return <>
              <button
                    type="button"
                    className={
                      "border border-black px-3 py-[7px] mt-2 rounded-xl bg-[#ebe4dc]"
                    }
                    onClick={() => setShowDialog(true)}
                  >
                    Add Gemstone
              </button>
              {formValues.gemstones.length>0 ? <div className="mt-2">
                <h2 className="font-semibold">Added Gemstones:</h2>
                <ul>
                  {formValues?.gemstones?.map((gemstone, index) => (
                    <li key={index} className="ml-8">
                    {gemstone && (
                      <>
                        <strong>Type:</strong> {gemstone.gemstoneType}, <strong>Count:</strong> {gemstone.gemstoneCount}, <strong>Cost per Stone:</strong> {gemstone.gemstoneCostPerStone}
                      </>
                    )}
                    <button type="button" onClick={() => handleGemstoneDelete(index)} className="ml-2">
                      <DeleteIcon />
                    </button>
                  </li>
                  ))}
                </ul>
              </div> : <p className="mt-2 font-serif text-gray-400 cursor-default">
                  &lt;&lt;&lt;No gemstones added yet. Start by adding your first gemstone!&gt;&gt;&gt;</p>}
              <RenderInput
                key={item.id}
                item={{
                  ...item,
                  error: errors?.[item.id] ? true : false,
                  helperText: errors?.[item.id] || "",
                }}
                state={formValues}
                stateHandler={setFormValues}
                setFocusedField={setFocusedField}
              />
            </>
          }

          if(item.id==='totalSellingPrice' && !isAdmin){
            return <></>
          }

          if(item.id==='sellerPrice'){
            item.title = isAdmin ? "Seller Price (Auto Calculated)" : "Your Selling Price";
          }

          return (
            <RenderInput
              key={item.id}
              previewOnly={
                state?.productId && item.id === "productCode" ? true : false
              }
              item={{
                ...item,
                error: errors?.[item.id] ? true : false,
                helperText: errors?.[item.id] || "",
              }}
              state={formValues}
              stateHandler={setFormValues}
              setFocusedField={setFocusedField}
            />
          );
        
        } else {
          return <></>
        }
      })}
      {/* <Button variant="outlined" style={{borderColor:"#b47437", margin:"4px 0"}} onClick={() => setShowDialog(true)}>
        <span style={{ fontWeight:"900", fontSize:"16px", fontFamily:"serif", color:"#b47437"}}>Add Gemstones</span>
      </Button> */}
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogContent>
          <GemstoneField
            gemstone={newGemstone}
            onChange={handleGemstoneChange}
            gemstoneFields={gemStone}
          /> 
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogCancel} className="!text-[#b47437]">
            Cancel
          </Button>
          <Button onClick={handleDialogSubmit} className="!text-[#b47437]">
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showGenerateNameDialog} onClose={() => setShowGenerateNameDialog(false)}>
        <DialogContent>
          <GenerateNamePopupFields
            state={formValues}
            statehandler={setFormValues}
          /> 
        </DialogContent>
        <DialogActions>
          <Button onClick={handleGenerateNameDialogCancel} className="!text-[#b47437]">
            Done
          </Button>
          {/* <Button onClick={handleGenerateNameDialogCancel} className="!text-[#b47437]">
            Submit
          </Button> */}
        </DialogActions>
      </Dialog>
      <Dialog open={showGenerateDescDialog} onClose={() => setShowGenerateDescDialog(false)}>
        <DialogContent>
          <GenerateDescPopupFields
            state={formValues}
            statehandler={setFormValues}
          /> 
        </DialogContent>
        <DialogActions>
          <Button onClick={handleGenerateDescDialogCancel} className="!text-[#b47437]">
            Done
          </Button>
          {/* <Button onClick={handleGenerateDescDialogCancel} className="!text-[#b47437]">
            Submit
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddProductInfo;
