import { countries } from "../../../Constants/countries";
import { PRODUCT_CATEGORY } from "../../../utils/constants";
export const UOMVariationFields = [];
export const gemStone = [
  {
    id: "gemstoneType",
    title: "Gemstone Type ",
    placeholder: "Please Select Gemstone Type",
    options: [
      { key: "CZ", value: "CZ" },
      { key: "Ruby", value: "Ruby" },
      { key: "Emerald", value: "Emerald" },
      { key: "Pearl", value: "Pearl" },
      { key: "Coral", value: "Coral" },
      { key: "Polki", value: "Polki" },
      { key: "Coloured Stones", value: "Coloured Stones" },
      { key: "Moissanite", value: "Moissanite" },
      { key: "Gomed (Hessonite Garnet)", value: "Gomed (Hessonite Garnet)" },
      { key: "Blue Sapphire", value: "Blue Sapphire" },
      { key: "Yellow Sapphire", value: "Yellow Sapphire" },
      { key: "Cat's Eye", value: "Cat's Eye" },
      { key: "Red Garnet", value: "Red Garnet" },
      { key: "Other Stones", value: "Other Stones" },
    ],
    type: "select",
    disableClearable: true,
    required: true,
  },
  // {
  //   id: "gemstoneSize",
  //   title: "Size (Optional)",
  //   placeholder: "Gemstone Size",
  //   type: "number",
  // },
  {
    id: "gemstoneCount",
    title: "No. of Pieces",
    placeholder: "Gemstone Count",
    type: "number",
    required: true,
  },
  {
    id: "gemstoneCostPerStone",
    title: "Cost per Piece (Rs.)",
    placeholder: "Gemstone Cost per Stone",
    type: "number",
    valueInDecimal: true,
    required: true,
  },
]
export const gemstoneFields = [
  {
    id: "gemstoneType",
    title: "Gemstone Type",
    placeholder: "Please Select Gemstone Type",
    options: [
      { key: "Coloured Stones", value: "Coloured Stones" },
      { key: "Ruby", value: "Ruby" },
      { key: "Emerald", value: "Emerald" },
      { key: "CZ", value: "CZ" },
      { key: "Pearl", value: "Pearl" },
      { key: "Coral", value: "Coral" },
      { key: "Garnet", value: "Garnet" },
      { key: "Blue Sapphire", value: "Blue Sapphire" },
      { key: "Yellow Sapphire", value: "Yellow Sapphire" },
      { key: "Cat's Eye", value: "Cat's Eye" },
    ],
    type: "select",
    disableClearable: true,
    required: true,
  },
  {
    id: "gemstoneSize",
    title: "Gemstone Size",
    placeholder: "Gemstone Size",
    type: "number",
    required: true,
  },
  {
    id: "dispatchTime",
    title: "Dispatch Time (in Days)",
    placeholder: "Enter Dispatch Time",
    type: "number",
    required: true,
  },
  {
    id: "wastageOnGold",
    title: "Wastage On Gold (in %)",
    placeholder: "E.g., 10",
    type: "number",
    required: true,
  },
  {
    id: "gemstoneCount",
    title: "Gemstone Count",
    placeholder: "Gemstone Count",
    type: "number",
    required: true,
  },
  {
    id: "gemstoneCostPerStone",
    title: "Gemstone Cost per Stone",
    placeholder: "Gemstone Cost per Stone",
    type: "number",
    valueInDecimal: true,
    required: true,
  },
];
export const allProductFieldDetails = [
  ...gemstoneFields,
  {
    id: "productCode",
    title: "Product Number (Tag Number)",
    placeholder: "E.g., 12001",
    type: "input",
    required: true,
  },
  {
    id: "productName",
    title: "Product Name (Short Title)",
    placeholder: "E.g., Pink-stone traditional jhumkas (BIS 916)",
    type: "input",
    required: true,
  },
  {
    id: "MRP",
    title: "MRP",
    placeholder: "MRP",
    type: "number",
    required: true,
    valueInDecimal: true,
  },
  {
    id: "gemstonePrice",
    title: "Gemstones Price (Auto Calculated)",
    placeholder: "Gemstones Price",
    type: "number",
    required: true,
    valueInDecimal: true,
    isDisabled:true,
  },
  {
    id: "sellerPrice",
    title: "Total Price(Auto Calculated)",
    placeholder: "Total Price",
    type: "number",
    required: true,
    valueInDecimal: true,
    isDisabled:true,
  },
  {
    id: "totalSellingPrice",
    title: "Selling Price (Including App Commission)",
    placeholder: "Selling Price (Including App Commission)",
    type: "number",
    required: true,
    valueInDecimal: true,
    isDisabled:true,
  },
  {
    id: "diamondCostPerCt",
    title: "Diamond Price (Rs./ct)",
    placeholder: "Diamond Price ",
    type: "number",
    required: true,
    // valueInDecimal: true,
  },
  {
    id: "makingCharges",
    title: "Making Charges per gram (Rs. / gm)",
    placeholder: "E.g., 50",
    type: "number",
    required: true,
    // valueInDecimal: true,
  },
  {
    id: "retailPrice",
    title: "Retail Price",
    placeholder: "Retail Price",
    type: "number",
    required: true,
    valueInDecimal: true,
  },
  {
    id: "purchasePrice",
    title: "Purchase Price",
    placeholder: "Purchase Price",
    type: "number",
    required: true,
    valueInDecimal: true,
  },
  {
    id: "HSNCode",
    title: "HSN Code",
    placeholder: "HSN Code",
    type: "input",
    required: true,
    isDisabled:true,
  },
  {
    id: "GST_Percentage",
    title: "GST Percentage",
    placeholder: "Please Select GST Percentage",
    type: "select",
    isDisabled:true,
    options: [
      { key: "0", value: 0 },
      { key: "3", value: 3 },
      { key: "5", value: 5 },
      { key: "12", value: 12 },
      { key: "18", value: 18 },
      { key: "28", value: 28 },
    ],
    required: true,
  },
  {
    id: "productCategory",
    title: "Product Category (Auto Filled)",
    placeholder: "Please Select Product Category (Auto Filled)",
    options: Object.entries(PRODUCT_CATEGORY).map(([key, value]) => {
      return { key: value, value: key };
    }),
    type: "select",
    disableClearable: true,
    required: true,
    isDisabled: true,
  },
  {
    id: "productSubcategory1",
    title: "Product Type",
    placeholder: "Please Select Product Type",
    options: [],
    type: "select",
    disableClearable: true,
    required: true,
  },
  {
    id: "manufacturerOrPackerName",
    title: "Manufacturer Or Packer Name",
    placeholder: "Manufacturer Or Packer Name",
    type: "input",
    required: true,
  },
  {
    id: "manufacturerOrPackerAddress",
    title: "Manufacturer Or Packer Address",
    placeholder: "Manufacturer Or Packer Address",
    type: "input",
    required: true,
  },
  //   {
  //     id: "commonOrGenericNameOfCommodity",
  //     title: "Common Or Generic Name Of Commodity",
  //     placeholder: "Common Or Generic Name Of Commodity",
  //     type: "input",
  //     required: true,
  //   },
  //   {
  //     id: "monthYearOfManufacturePackingImport",
  //     title: "Month Year Of Manufacture Packing Import",
  //     placeholder: "Month YearOf Manufacture Packing Import",
  //     type: "date-picker",
  //     required: true,
  //     format: "MM/YYYY",
  //     views: ["year", "month"],
  //   },
  //   {
  //     id: "importerFSSAILicenseNo",
  //     title: "Importer FSSAI License No",
  //     placeholder: "Importer FSSAI License No",
  //     type: "number",
  //     maxLength: 14,
  //     required: true,
  //   },
  //   {
  //     id: "brandOwnerFSSAILicenseNo",
  //     title: "Brand Owner FSSAI License No",
  //     placeholder: "Brand Owner FSSAI License No",
  //     type: "number",
  //     maxLength: 14,
  //     required: true,
  //   },

  {
    id: "quantity",
    title: "Net Quantity (in inventory)",
    placeholder: "Net Quantity (in inventory)",
    type: "number",
    required: true,
  },
  //   {
  //     id: "barcode",
  //     title: "SKU",
  //     placeholder: "SKU",
  //     type: "number",
  //     maxLength: 12,
  //     required: true,
  //   },
  //   {
  //     id: "maxAllowedQty",
  //     title: "Max Allowed Quantity",
  //     placeholder: "Max Allowed Quantity",
  //     type: "number",
  //     required: true,
  //     min: 1,
  //     maxLength: 10,
  //   },
  {
    id: "UOM",
    title: "UOM",
    placeholder: "UOM",
    type: "select",
    required: true,
    options: [
      { key: "unit", value: "unit" },
      { key: "dozen", value: "dozen" },
      { key: "gram", value: "gram" },
      { key: "kilogram", value: "kilogram" },
      { key: "tonne", value: "tonne" },
      { key: "litre", value: "litre" },
      { key: "millilitre", value: "millilitre" },
    ],
  },
  {
    id: "UOMValue",
    title: "UOM value",
    placeholder: "UOM value",
    type: "input",
    required: true,
  },
  //   {
  //     id: "length",
  //     title: "Length(cm)",
  //     placeholder: "Length",
  //     type: "input",
  //     maxLength: 6,
  //     required: true,
  //   },
  //   {
  //     id: "breadth",
  //     title: "Breadth(cm)",
  //     placeholder: "Breadth",
  //     type: "input",
  //     maxLength: 6,
  //     required: true,
  //   },
  //   {
  //     id: "height",
  //     title: "Height(cm)",
  //     placeholder: "Height",
  //     type: "input",
  //     maxLength: 6,
  //     required: true,
  //   },
  {
    id: "weight",
    title: "Gross Weight (in gm)",
    placeholder: "E.g., 4.230",
    type: "number",
    required: true,
  },
  {
    id: "net_weight",
    title: "Net Weight (in gm)",
    placeholder: "E.g., 4.230",
    type: "number",
    required: true,
  },
  {
    id: "diamonds_weight",
    title: "Diamonds Weight (ct)",
    placeholder: "Diamonds Weight(ct)",
    type: "input",
    maxLength: 7,
    required: true,
  },
  {
    id: "returnWindow",
    title: "Return Window (in days)",
    placeholder: "Return Window (in days)",
    type: "number",
    maxLength: 3,
    required: true,
  },
  {
    id: "manufacturerName",
    title: "Manufacturer Name",
    placeholder: "Manufacturer Name",
    type: "input",
    maxLength: 50,
    required: true,
  },
  {
    id: "manufacturedDate",
    title: "Manufactured Date",
    placeholder: "Manufactured Date",
    type: "date-picker",
    required: true,
  },
  //   {
  //     id: "nutritionalInfo",
  //     title: "Nutritional Info",
  //     placeholder: "Nutritional Info",
  //     type: "input",
  //     required: true,
  //   },
  //   {
  //     id: "additiveInfo",
  //     title: "Additive Info",
  //     placeholder: "Additive Info",
  //     type: "input",
  //     required: true,
  //   },
  //   {
  //     id: "instructions",
  //     title: "Instructions",
  //     placeholder: "Instructions",
  //     type: "input",
  //     required: true,
  //   },
  //   {
  //     id: "longDescription",
  //     title: "Long Description",
  //     placeholder: "Long Description",
  //     type: "input",
  //     required: true,
  //     multiline: true,
  //   },
  {
    id: "description",
    title: "Product Description (Details about the product)",
    placeholder: "E.g., Elegant and traditional jhumkas adorned with pink stones, and moti drops. BIS 916 Hallmarked jewellery...",
    type: "input",
    required: true,
    multiline: true,
  },
  //   {
  //     id: "vegNonVeg",
  //     title: "Veg/Non-Veg/Egg",
  //     placeholder: "Select Food Category",
  //     type: "select",
  //     options: [
  //       { key: "Veg", value: "VEG" },
  //       { key: "Non Veg", value: "NONVEG" },
  //       { key: "Egg", value: "EGG" },
  //     ],
  //     disableClearable: true,
  //     required: true,
  //   },
  {
    id: "isCancellable",
    title: "Cancellable",
    type: "radio",
    options: [
      { key: "Yes", value: "true" },
      { key: "No", value: "false" },
    ],
    required: true,
  },
  {
    id: "isReturnable",
    title: "Returnable",
    type: "radio",
    options: [
      { key: "Yes", value: "true" },
      { key: "No", value: "false" },
    ],
    required: true,
  },
  {
    id: "availableOnCod",
    title: "Available On Cash On Delivery",
    type: "radio",
    options: [
      { key: "Yes", value: "true" },
      { key: "No", value: "false" },
    ],
    required: true,
  },
  {
    id: "images",
    title:
      "Product Photos/Videos (Upload minimum 2 images/videos)",
    type: "upload",
    multiple: true,
    file_type: "product_image",
    required: true,
  },
  {
    id: "certifications",
    title: "Upload Certification",
    type: "upload",
    multiple: true,
    file_type: "product_image",
    required: true,
  },
  {
    id: "fulfillmentOption",
    title: "Fulfilment Option",
    placeholder: "Available Fulfillment Options",
    type: "select",
    required: true,
    options: [
      { value: "delivery", key: "Delivery" },
      { value: "pickup", key: "Pickup" },
    ],
    disableClearable: true,
  },
  {
    id: "countryOfOrigin",
    title: "Country Of Origin",
    placeholder: "Country Of Origin",
    type: "select",
    options: countries,
    required: true,
  },
  {
    id: "jewelleryCategory",
    title: "Jewellery Category",
    placeholder: "Please Select Jewellery Category",
    options: [
      { key: "Women's Jewellery", value: "women" },
      { key: "Men's Jewellery", value: "men" },
      
    ],
    type: "select",
    disableClearable: true,
    required: true,
  },

  {
    id: "jewelleryType",
    title: "Jewellery Type",
    placeholder: "Please Select Jewellery Type",
    options: [
      { key: "Gold", value: "gold" },
      { key: "Diamond", value: "diamond" },
      
      // { key: "Silver", value: "silver" },
    ],
    type: "select",
    disableClearable: true,
    required: true,
  },
  {
    id: "diamondColour",
    title: "Diamond Colour",
    placeholder: "Please Select Diamond Colour",
    options: [
      { key: "D", value: "D" },
      { key: "E", value: "E" },
      { key: "F", value: "F" },
      { key: "G", value: "G" },
      { key: "H", value: "H" },
      { key: "I", value: "I" },
      { key: "J", value: "J" },
      { key: "K", value: "K" },
      { key: "L", value: "L" },
      { key: "M", value: "M" },
    ],
    type: "select",
    disableClearable: true,
    required: true,
  },
  {
    id: "diamondClarity",
    title: "Diamond Clarity",
    placeholder: "Please Select Diamond Clarity",
    options: [
      { key: "SI3", value: "SI3" },
      { key: "SI2", value: "SI2" },
      { key: "SI1", value: "SI1" },
      { key: "VS2", value: "VS2" },
      { key: "VS1", value: "VS1" },
      { key: "VVS1", value: "VVS1" },
      { key: "VVS2", value: "VVS2" },
      { key: "IF", value: "IF" },
      { key: "FL", value: "FL" },
    ],
    type: "select",
    disableClearable: true,
    required: true,
  },
  {
    id: "Purity",
    title: "Purity",
    placeholder: "Please Select Purity",
    options: [
      { key: "22 KT", value: "22" },
      { key: "18 KT", value: "18" },
      { key: "14 KT", value: "14" },
    ],
    type: "select",
    disableClearable: true,
    required: true,
  },
  // {
  //   id: "huidNumber",
  //   title: "HUID Number",
  //   placeholder: "HUID Number",
  //   type: "number",
  //   required: true,
  // },

  {
    id: "huidDone",
    title: "HUID Done?",
    placeholder: "HUID Done?",
    type: "select",
    options: [
      { key: "Yes", value: "true" },
      { key: "No", value: "false" },
    ],
    required: true,
  },
  {
    id: "Finishing",
    title: "Finishing",
    placeholder: "Please Select Finishing",
    options: [
      { key: "Yellow Gold", value: "yellow" },
      { key: "Rose Gold", value: "rose" },
      { key: "White Gold", value: "white" },
      { key: "Silver", value: "silver" },
    ],
    type: "select",
    disableClearable: true,
    required: true,
  },
];

export const categoryFields = [
  "productCategory",
  "productSubcategory1",
  "jewelleryCategory",
  "jewelleryType",
];

export const productDetailsFields = [
  // basic
  "images",
  "productCode",
  "productName",
  "description",
  "net_weight",
  "weight",
  "Finishing",
  "Purity",
  // "huidNumber",
  "huidDone",
  "quantity",

  // diamond details
  "diamonds_weight",
  "diamondColour",
  "diamondClarity",
  "certifications",

  // pricing
  "diamondCostPerCt",
  "wastageOnGold",
  "makingCharges",
  "GST_Percentage",
  "gemstonePrice",
  "sellerPrice",           //  variants_support (5): Uncomment this to turn on variant suport.
  "totalSellingPrice",     //  variants_support (4): Uncomment this to turn on variant suport.
];

export const _deliveryFieldsDetails = [
{
  id: "fulfillmentOption",
  title: "Fulfilment Option",
  placeholder: "Available Fulfillment Options",
  type: "select",
  required: true,
  options: [
    { value: "delivery", key: "Delivery" },
    { value: "pickup", key: "Pickup" },
  ],
  disableClearable: true,
},
{
  id: "dispatchTime",
  title: "Dispatch Time (in Days)",
  placeholder: "Enter Dispatch Time",
  type: "number",
  required: true,
},
{
  id: "isCancellable",
  title: "Cancellable",
  type: "radio",
  options: [
    { key: "Yes", value: "true" },
    { key: "No", value: "false" },
  ],
  required: true,
},
{
  id: "isReturnable",
  title: "Returnable",
  type: "radio",
  options: [
    { key: "Yes", value: "true" },
    { key: "No", value: "false" },
  ],
  required: true,
},
{
  id: "returnWindow",
  title: "Return Window (in days)",
  placeholder: "Return Window (in days)",
  type: "number",
  maxLength: 3,
  required: true,
},
]

export const _deliveryFields = [
  // delivery details
  "fulfillmentOption",
  "dispatchTime",
  "isCancellable",
  "isReturnable",
  "returnWindow",
]

export const ecomFieldsDetails = [
  {
    id: "countryOfOrigin",
    title: "Country Of Origin",
    placeholder: "Country Of Origin",
    type: "select",
    options: countries,
    required: true,
  },
  {
    id: "manufacturerOrPackerName",
    title: "Manufacturer Or Packer Name",
    placeholder: "Manufacturer Or Packer Name",
    type: "input",
    required: true,
  },
  {
    id: "manufacturerOrPackerAddress",
    title: "Manufacturer Or Packer Address",
    placeholder: "Manufacturer Or Packer Address",
    type: "input",
    required: true,
  },
  {
    id: "HSNCode",
    title: "HSN Code",
    placeholder: "HSN Code",
    type: "input",
    required: true,
    isDisabled:true,
  },
]
export const ecomFields = [
  // ecom details
  "countryOfOrigin",
  "manufacturerOrPackerName",
  "manufacturerOrPackerAddress",
  "HSNCode",
]

export const AutoFillFields = [];

//  variants_support (6): Comment Out the below one and Uncomment the next one to below for turning on variants_support.
// export const variationCommonFields = ["sellerPrice", "totalSellingPrice"];             // For general.
export const variationCommonFields = ["images", "productCode", "net_weight", "weight", "quantity", "diamonds_weight", "diamondColour", "certifications", "gemstonePrice", "sellerPrice", "totalSellingPrice"];             // For variants_support[latest].
// export const variationCommonFields = ["quantity", "MRP", "images", "purchasePrice"];   // For variants_support.
