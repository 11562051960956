import {
    Button,
    Modal,
    Radio,
    RadioGroup,
    FormControl,
    FormControlLabel,
    Box,
    CircularProgress,
    useTheme
  } from "@mui/material";
import { getCall, postCall } from "../../../Api/axios";
import cogoToast from "cogo-toast";
import { useEffect, useState } from "react";
import { getFullAddress } from "./../../../utils/orders.js";

const DeliveryOptionsModal = (props) => {
    const { showModal, handleCloseModal, loading, setloading, order, delivery_info, defaultDeliveryOption} = props;
    const [deliveryOption, setDeliveryOption] = useState(order.selected_delivery_option || defaultDeliveryOption);
    const [switchToPlatform, setSwitchToPlatform] = useState(false);
    const theme = useTheme();

    const optionsMapping = {
      platform_fulfilled: "Outsource Delivery",
      seller_fulfilled: "Deliver Yourself"
    };

    useEffect(()=>{
        if(showModal){
            setloading((prevState)=>({
                ...prevState,
                delivery_option_loading:true
            }));
        } else{
            setloading((prevState)=>({
                ...prevState,
                delivery_option_loading:false
            }));
        }
    },[showModal]);

    useEffect(()=>{
      if(defaultDeliveryOption === "seller_fulfilled" && deliveryOption === "platform_fulfilled"){
        setSwitchToPlatform(true);
      } else{
        setSwitchToPlatform(false);
      }
    }, [deliveryOption, defaultDeliveryOption]);

    const handleDeliveryOptionsUpdate = async() => {
      if(deliveryOption===order.selected_delivery_option){
        cogoToast.error(`Delivery Option is already set to "${optionsMapping[deliveryOption]}"`);
        handleCloseModal();
        return;
      }
      const url = `/api/v1/orders/${order._id}/delivery`;
      setloading({ ...loading, delivery_modal_loading: true });
      await postCall(url, { selected_delivery_option : deliveryOption})
        .then((res)=>{
          cogoToast.success(res?.msg);
          setloading({ ...loading, delivery_modal_loading: false });
          handleCloseModal(true);
      }).catch((error)=>{
          handleCloseModal();
          cogoToast.error(error?.response?.data?.error);
      })
    }

    const sellerLabel=()=>{
      return (
        <Box component="span">
          <strong>Deliver Yourself: </strong>You will deliver the product to the buyer at the above address and update order status on the app.
        </Box>
      )
    }
    const platformLabel=()=>{
      return (
        <Box component="span">
          <strong>Outsource Delivery: </strong>Let Aurika Tech fully manage the delivery.
        </Box>
      )
    }

    return (
        <Modal
          open={showModal}
          onClose={() => {
            handleCloseModal();
            setDeliveryOption(order.selected_delivery_option || defaultDeliveryOption);
          }}
          width={100}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#fff",
              padding: "24px 40px",
              borderRadius: 20,
            }}
          >
            <p className="font-semibold text-xl" style={{ marginBottom: 10 }}>
              Delivery Options
            </p>

            <p>
                Please choose your preferred delivery option for this delivery address:-
            </p>
            <div className="mx-auto my-2 max-w-[32rem] px-4 py-2 border border-black rounded-lg"
                // style={{maxWidth:"32rem"}}
            >
                <p><span className="font-bold">Name - </span>{delivery_info?.end?.location?.address?.name}</p>
                <p><span className="font-bold">Address - </span>{getFullAddress(delivery_info?.end?.location?.address)?.split(',').join(', ') || 'Address Unavailable'}</p>
            </div>

            <FormControl>
                <RadioGroup
                    name="delivery_options"
                    value={deliveryOption}
                    onChange={(e)=>setDeliveryOption(e.target.value)}
                >
                    <FormControlLabel value="seller_fulfilled" control={<Radio/>} label={sellerLabel()}/>
                    <FormControlLabel value="platform_fulfilled" control={<Radio/>} label={platformLabel()}/>
                </RadioGroup>
            </FormControl>
            {/* To-Do: show delivery fees in the following warning message*/}
            {switchToPlatform && <p className="ml-6 text-md text-yellow-500 cursor-default">
              We can manage the delivery, but the delivery charges will be deducted from your settlement.
            </p>}
            <div className="flex justify-end mt-4">
              <Button
                variant="outlined"
                color="tertiary"
                disabled={loading.delivery_modal_loading}
                onClick={handleDeliveryOptionsUpdate}
              >
                {loading.delivery_modal_loading ? (
              <>
                Confirm&nbsp;&nbsp;
                <CircularProgress size={18} sx={{ color: theme.palette.tertiary.main }} />
              </>
            ) : (
              <span>Confirm</span>
            )}
              </Button>
              <Button
                sx={{ marginLeft: 2 }}
                color="tertiary"
                onClick={() => {
                  handleCloseModal();
                  setDeliveryOption(order.selected_delivery_option || defaultDeliveryOption);
                }}
              >
                Close
              </Button>
            </div>
          </div>
        </Modal>
    );
  };

  export default DeliveryOptionsModal;