import React, { useEffect, useState } from "react";
import { TextField, MenuItem, CircularProgress } from "@mui/material";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import cogoToast from 'cogo-toast';
import { postCall } from "../../../Api/axios";

const GenerateDescPopupFields = ({ state, statehandler }) => {
    let myBucket = process.env.REACT_APP_S3_BUCKET;
    let regionString = '-' + process.env.REACT_APP_S3_REGION;
    const baseUrl = `https://${myBucket}.s3${regionString}.amazonaws.com/`;
    const API_URL = "/api/v1/llm/getProductDescription";

    console.log("[GenerateDescPopupFields] state: ", state);
    let prompt = (state.productTitle||state.productName) +  ". Generate 3 product description ideas for this " + state.productSubcategory1 + ". Each product description should be succinct, simple, and easy-to-understand. It should have only 2 sentences. The first sentence should concisely describe the value that the earring will add to the customer. The last sentence should start with 'Features ' and should describe the design of the earring in a descriptive & captivating yet crisp manner.";

    const models = [
        "gemini-1.5-flash",
        "gemini-2.0-flash-exp",
        "gemini-2.0-flash-thinking-exp",
    ];
    const [currentModel, setCurrentModel] = useState("gemini-2.0-flash-exp");
    const [productDesc, setProductDesc] = useState([]);
    const [currentProductDesc, setCurrentProductDesc] = useState("");
    const [loading, setLoading] = useState(false);
    const [userPrompt, setUserPrompt] = useState(prompt);
    const validExtensions = ["jpg", "jpeg", "png", "webp", "heic", "heif"];
    let imageUrl = "";
    if (state.images && state.images.length > 0) {
        for (let i = 0; i < state.images.length; i++) {
            const ext = state.images[i].split(".").pop();
            if (validExtensions.includes(ext)) {
                imageUrl = baseUrl + state.images[i];
                // todo: check whether the imageUrl is valid or not. assess whether we need to implement this
                break;
            }
        }
    }

    if (imageUrl === "") {
        cogoToast.error("No valid image found to generate product descriptions!");
        console.error("[GenerateDescPopupFields] No valid image found to generate product descriptions! validExtensions: ", validExtensions, " state.images: ", state.images);
    }
    function capitalizeFirstLetter(val) {
        return String(val).charAt(0).toUpperCase() + String(val).slice(1);
    }

    const generateProductDesc = async () => {
        try {
            console.log("[GenerateDescPopupFields] generateProductDesc imageUrl: ", imageUrl);
            if (imageUrl === "") {
                return;
            }
            setLoading(true);
            const data = {
                "model": currentModel,
                "userPrompt": userPrompt,
                "imageUrl": imageUrl
            };
            console.log("[GenerateDescPopupFields] generateProductDesc data: ", data);
            const response = await postCall(API_URL, data);
            console.log("[GenerateDescPopupFields] generateProductDesc response: ", response);
            cogoToast.success("Product descriptions generated successfully!");
            setLoading(false);
            setProductDesc(response.productDescriptions);
        }
        catch (error) {
            console.error("[GenerateDescPopupFields] generateProductDesc error: ", error);
            setLoading(false);
            cogoToast.error("Failed to generate product descriptions! see console for more info");
        }  
    };

    function updateProductDescFromData(data) {
        let fullDesc = data;
        let sentence1=data, sentence2="";
        if (fullDesc.includes(".")) {
            let sentences = fullDesc.split(". ");
            sentence1 = sentences[0];
            sentence2 = sentences[1];
        }
        fullDesc=`- ${sentence1}.
- Gold & KT: BIS ${state.Purity == "22" ? "916 " : ""}Hallmarked (${state.Purity}KT) | ${capitalizeFirstLetter(state.Finishing)} Gold | Net Weight: ${parseFloat(state.net_weight).toFixed(2)} grams | Gross Weight: ${parseFloat(state.weight).toFixed(2)} grams.
- Certification: 100% BIS Hallmarked with HUID for guaranteed authenticity.
- Design: ${sentence2}
- Price Advantage: Exclusive lightweight design with the lowest making charges in the market`;

        let id = "outlined-multiline-static1";
        let element = document.getElementById(id);
        setCurrentProductDesc(data);
        statehandler({ ...state, description: fullDesc });
        if (element) {
            element.value = data;
        }
    }

    useEffect(() => {
        generateProductDesc();
    }, []);

    useEffect(() => {
        let prompt = (state.productTitle||state.productName) +  ". Generate 3 product description ideas for this " + state.productSubcategory1 + ". Each product description should be succinct, simple, and easy-to-understand. It should have only 2 sentences. The first sentence should concisely describe the value that the earring will add to the customer. The last sentence should start with 'Features ' and should describe the design of the earring in a descriptive & captivating yet crisp manner.";
        setUserPrompt(prompt);
    }, [state.productTitle]);

  return (
    <div className="md:w-[24rem] mx-[1rem]">
        <img src= {imageUrl} alt="Product" className="w-[24rem] h-[24rem]"
         style={{marginTop: "7px"}} />
        <details>
            <summary>Advanced Settings</summary>
            <div>
                {/* select to choose model */}

                <TextField
                    id="outlined-select-currency"
                    select
                    label="Model"
                    value={currentModel}
                    onChange={(e) => setCurrentModel(e.target.value)}
                    helperText="Please select a model"
                    variant="outlined"
                    fullWidth
                    style={{marginTop: "1rem"}}
                >
                    {models.map((option) => (
                        <MenuItem key={option} value={option} style={{justifyContent: "left", paddingLeft: "1rem"}}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>

                {/* textarea */}
                <TextField
                    id="outlined-multiline-static"
                    label="Prompt"
                    multiline   
                    onChange={(e) => setUserPrompt(e.target.value)}
                    rows={4}
                    defaultValue={userPrompt}
                    variant="outlined"
                    fullWidth
                    style={{marginTop: "1rem"}}
                />
                <button 
                    type="button"
                    className="bg-blue-500 hover:bg-blue-700 mx-[1rem] text-white font-bold py-2 px-4 rounded mt-2"
                    onClick={()=>{
                        generateProductDesc();
                    }}
                >
                    <AutoFixHighIcon />
                    Generate
                </button>
            </div>
        </details>
        {loading ? 
        <div className="md:w-[24rem] mx-[1rem] flex justify-center">
            <CircularProgress /> 
        </div>
            : 
        <div className="md:w-[24rem] mx-[1rem]">
            <h2 style={{marginTop: "1rem", fontWeight: "bold"}}>
                Choose a description for your product
            </h2>
            {productDesc.map((desc, index) => (
                <div className="mt-2" key={index}>
                    <input type="radio" id={index} name="productDesc" className="mr-2" onChange={() => {
                            updateProductDescFromData(desc)
                        }
                    } />
                    <label for={index} className="text-lg">
                        {desc}
                    </label>
                </div>
            ))}
            <TextField
                id="outlined-multiline-static1"
                label="Product Description"
                multiline   
                onChange={(e) => {
                    updateProductDescFromData(e.target.value);
                }}
                rows={4}
                defaultValue={"Choose a description for your product"}
                variant="outlined"
                fullWidth
                style={{marginTop: "1rem"}}
            />
        </div>}
    </div>
    );
};

export default GenerateDescPopupFields;
