import { useEffect, useState } from "react";
import { useNavigate, useParams, Link as RouterLink } from "react-router-dom";
import { CircularProgress, Divider, Link} from "@mui/material";
import BackNavigationButton from "../../Shared/BackNavigationButton";
import { getCall } from "../../../Api/axios";
import { convertDateInStandardFormat } from "../../../utils/formatting/date";
import SettlementUpdatesForm from "./SettlementUpdatesForm";
import { counterpartyReconStatusMap, orderReconStatusMap } from "./SettlementsTable";

const SettlementDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [settlementInfo, setSettlementInfo] = useState();
  const [settlementOrderInfo, setSettlementOrderInfo] = useState();
  const [offerQuotes, setOfferQuotes] = useState();
  const [receiverReconOrder, setReceiverReconOrder] = useState();
  const params = useParams();
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };

  //fetch settlement info
  useEffect(()=>{
    setIsLoading(true);
    getSettlementInfo();
  }, []);

  useEffect(()=>{
    if(settlementOrderInfo){
      const offerQuotes = settlementOrderInfo
          .quote.breakup
          .filter(b=>b["@ondc/org/title_type"]==="offer");
      if(settlementOrderInfo.finderFeeType==='percent'){
        let deliveryOfferQuote = offerQuotes.find(o=>o.item.tags.find(t=>t.code==="offer").list.find(l=>l.code==="type").value==="delivery");
        if(deliveryOfferQuote)
          deliveryOfferQuote.price.value = (parseFloat(deliveryOfferQuote.price.value)/(1+(parseFloat(settlementOrderInfo.finderFeeAmount)/100))).toFixed(2);
      }
      setOfferQuotes(offerQuotes);
    }
  }, [settlementOrderInfo]);

  const getSettlementInfo = () => {
    getCall(`/api/v1/settlements/singleSettlement?settlementId=${params.settlementId}`)
    .then((res)=>{
      console.log("settlement info fetched : ")
      console.log(res);
      const reconUrl = `/api/v1/reconOrder/_latest_/${res.data.settlementOrder.orderId}`;
      getCall(reconUrl).then((res) => {
        console.log("Receiver Recon Request Order: ", res.ReceiverReconOrder.data);
        setReceiverReconOrder(res.ReceiverReconOrder.data);
      }).catch((error)=>console.log(error))
      setSettlementInfo(res.data);
      setSettlementOrderInfo(res.data.settlementOrder);
      setIsLoading(false);
    })
    .catch((error)=>{
      console.error(error);
      setIsLoading(false);
    })
  }
  const onUpdateSettlementComplete = () => {
    getSettlementInfo();
  }

  return (
    <div className="container mx-auto my-8 px-2 md:px-8">
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            backgroundColor: "#fff",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <BackNavigationButton onClick={handleGoBack} />
          <div className="flex flex-col">
            {settlementOrderInfo && 
            <div className={`border-2 border-gray-200 rounded-lg bg-slate-50 my-4 p-4`}>
              {/* .... Order Section.... */}
              <div className="flex justify-start">
                <p className="text-lg font-semibold mb-2">Order Summary</p>
              </div>
              {/* .... Order Sub-Section.... */}
            <Divider orientation="horizontal" sx={{borderBottomWidth:"3px"}}/>
              <div className="flex justify-start">
                <p className="text-md font-semibold mt-1 mb-1">Order Details</p>
              </div>
            <Divider orientation="horizontal" sx={{borderBottomWidth:"3px"}}/>
              <div className="flex justify-between mb-2">
                <p className="text-base font-normal">Order Id</p>
                <p className="text-base font-normal">{settlementOrderInfo.orderId}</p>
              </div>
              <div className="flex justify-between mb-2">
                <p className="text-base font-normal">Order Date</p>
                <p className="text-base font-normal">{convertDateInStandardFormat(settlementOrderInfo.createdAt)}</p>
              </div>
              <div className="flex justify-between mb-2">
                <p className="text-base font-normal">Order Status</p>
                <p className="text-base font-normal">{settlementOrderInfo.state}</p>
              </div>
              <div className="flex justify-between mb-2">
                <p className="text-base font-normal">Buyer Name</p>
                <p className="text-base font-normal">{settlementOrderInfo.billing.name}</p>
              </div>
              {/* .... Order Sub-Section.... */}
            <Divider orientation="horizontal" sx={{borderBottomWidth:"3px"}}/>
              <div className="flex justify-start">
                <p className="text-md font-semibold mt-1 mb-1">Payment Details</p>
              </div>
            <Divider orientation="horizontal" sx={{borderBottomWidth:"3px"}}/>
              <div className="flex justify-between mb-2">
                <p className="text-base font-normal">Total Amount</p>
                <p className="text-base font-normal">{"₹"+settlementOrderInfo.payment.params.amount}</p>
              </div>
              <div className="flex justify-between mb-2">
                <p className="text-base font-normal">Payment Method</p>
                <p className="text-base font-normal">{settlementOrderInfo.payment.type}</p>
              </div>
              <div className="flex justify-between mb-2">
                <p className="text-base font-normal">Payment Status</p>
                <p className="text-base font-normal">{settlementOrderInfo.payment.status}</p>
              </div>
            </div> }
            {/* .... Settlement Section.... */}
            { settlementInfo &&
            <div className={`border-2 border-gray-200 rounded-lg bg-slate-50 my-4 p-4`}>
              <div className="flex justify-start">
                <p className="text-lg font-semibold mb-2">Settlement Summary</p>
              </div>
            {/* .... Settlement Sub-Section.... */}
            <Divider orientation="horizontal" sx={{borderBottomWidth:"3px"}}/>
              <div className="flex justify-start">
                <p className="text-md font-semibold mt-1 mb-1">Settlement Details</p>
              </div>
            <Divider orientation="horizontal" sx={{borderBottomWidth:"3px"}}/>
              <div className="flex justify-between mb-2">
                <p className="text-base font-normal">Provider Store Name</p>
                <p className="text-base font-normal">{settlementInfo.providerStoreName}</p>
              </div>
              <div className="flex justify-between mb-2">
                <p className="text-base font-normal">Seller GSTIN</p>
                <p className="text-base font-normal">{settlementInfo.sellerGSTIN}</p>
              </div>
              {offerQuotes?.length>0 && <div className="flex justify-between mb-2">
                <p className="text-base font-normal">Seller's Total Consideration [before Offer(s)]</p>
                <p className="text-base font-normal">
                {"₹"+(parseFloat(settlementInfo.sellerTotalConsideration)
                      + Math.abs(offerQuotes
                        .reduce((acc,ofr)=>acc+=parseFloat(ofr?.price?.value), 0))).toFixed(2)}
                </p>
              </div>}
              <div className="flex justify-between mb-2">
                <p className="text-base font-normal">Seller's Total Consideration{`${offerQuotes?.length>0 ? ' [after Offer(s)]' : ''}`}</p>
                <p className="text-base font-normal">
                {"₹"+settlementInfo.sellerTotalConsideration}
                </p>
              </div>
              {offerQuotes?.length>0 && (<div className="flex justify-between mb-2">
                  <p className="text-base font-normal">Offer(s) Applied</p>
                  <div className="text-end">
                    {
                      offerQuotes.map((offer)=>{
                        return <p>{offer?.price?.value?.includes('-')
                                    ? `-₹${Math.abs(offer.price.value)}`
                                    : '₹'+offer.price.value} (<span className="text-[#d4b48e]">{
                          offer.item?.tags?.find(tag=>tag.code=='offer').list.find(listItem=>listItem.code=='id').value
                        }</span>)</p>
                      })
                    }
                  </div>
                </div>)
              }
              {
                (!isNaN(settlementInfo.platformDeliveryCharge) && settlementInfo.platformDeliveryCharge != 0) && <div className="flex justify-between mb-2">
                <p className="text-base font-normal">Platform Delivery Charge</p>
                <p className="text-base font-normal">
                {"₹"+settlementInfo.platformDeliveryCharge}
                </p>
              </div>
              }
              <div className="flex justify-between mb-2">
                <p className="text-base font-normal">TDS Deduction</p>
                <p className="text-base font-normal">{"₹"+settlementInfo.tdsDeduction}</p>
              </div>
              <div className="flex justify-between mb-2">
                <p className="text-base font-normal">TCS Deduction</p>
                <p className="text-base font-normal">{Object.keys(settlementInfo.tcsDeduction).length===0
                                                      ? "₹0"
                                                      : settlementInfo.tcsDeduction.igst
                                                        ? "IGST: ₹"+settlementInfo.tcsDeduction.igst
                                                        : <div>
                                                            <p>{"CGST: ₹"+settlementInfo.tcsDeduction.cgst}</p>
                                                            <p>{"SGST: ₹"+settlementInfo.tcsDeduction.sgst}</p>
                                                          </div>}</p>
              </div>
              <div className="flex justify-between mb-2">
                <p className="text-base font-normal">Settlement Amount To Seller</p>
                <p className="text-base font-normal">{"₹"+settlementInfo.settlementAmountToSeller}</p>
              </div>
              <div className="flex justify-between mb-2">
                <p className="text-base font-normal">Counterparty Recon Status</p>
                <p className="text-base font-normal">{counterpartyReconStatusMap[settlementInfo.counterpartyReconStatus] || '-'}</p>
              </div>
              <div className="flex justify-between mb-2">
                <p className="text-base font-normal">Order Recon Status</p>
                <p className="text-base font-normal">{orderReconStatusMap[settlementInfo.orderReconStatus] || '-'}</p>
              </div>
              <div className="flex justify-between mb-2">
                <p className="text-base font-normal">Corresponding Receiver Recon Request</p>
                <Link component={RouterLink} to={receiverReconOrder?.receiverReconRequestId ? `/receiver-recon-orders/${receiverReconOrder?.receiverReconRequestId}`: ''} color="secondary" underline="hover">{receiverReconOrder?.receiverReconRequestId || 'NA'}</Link>
              </div>
            {/* .... Settlement Sub-Section.... */}
            <Divider orientation="horizontal" sx={{borderBottomWidth:"3px"}}/>
              <div className="flex justify-start">
                <p className="text-md font-semibold mt-1 mb-1">Settlement Updates</p>
              </div>
            <Divider orientation="horizontal" sx={{borderBottomWidth:"3px"}}/>
            <SettlementUpdatesForm settlementInfo={settlementInfo} onUpdateSettlementComplete={onUpdateSettlementComplete}/>
            </div> }
          </div>
        </>
      )}
    </div>
  );
};
export default SettlementDetails;
