import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Menu,
  MenuItem,
  Button,
  Stack,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MoreVert as MoreVertIcon, LockOutlined } from "@mui/icons-material";
import { putCall, getCall } from "../../../Api/axios";
import { Tooltip } from "@material-ui/core";
import cogoToast from "cogo-toast";
import { useTheme, styled} from "@mui/material/styles";
import TableActionsComponent from "../../Shared/TableActionsComponent";

const StyledTableCell = styled(TableCell)({
  "&.MuiTableCell-root": {
    fontWeight: "bold",
  },
});
const StyledMoreVertIcon = styled(MoreVertIcon)(({theme})=>({
  color : theme.palette.tertiary.main
}));

const ThreeDotsMenu = (props) => {
  const { row, isProvider, getAdmins, getProviders, view } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [docApprovalStatus, setDocApprovalStatus] = useState("");
  const navigate = useNavigate();

  const openActionMenu = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const action = async (e, action) => {
    e.stopPropagation();
    let resp;
    try {
      switch (action) {
        case "enable":
          resp = await putCall(`/api/v1/users/${row?._id}/enable`, { enabled: true });
          break;
        case "disable":
          resp = await putCall(`/api/v1/users/${row?._id}/enable`, { enabled: false });
          break;
        case "unlock":
          resp = await putCall(`/api/v1/auth/grantAccess/${row?._id}`);
          break;
        default:
          break;
      }

      if(resp?.success === false){
        console.log(typeof resp?.message);
        cogoToast.error(resp.message);
      }

      setAnchorEl(null);
      if (view === "admin") {
        getAdmins();
      } else {
        getProviders();
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    // Fetch document approval status when the component mounts
    const fetchDocVerificationStatus = async () => {
      try {
        const url = `/api/v1/organizations/${row?.organization?._id}`;
        console.log("Organization object", row?.organization);
        const data = await getCall(url);
        console.log("Data", data);
        console.log("Provider detail", data.providerDetail);

        const approval = data.providerDetail?.documentApprovalStatus;

        console.log("Approval", approval);
        setDocApprovalStatus(approval);
      } catch (error) {
        console.error("Error fetching document verification status:", error);
        setDocApprovalStatus("error");
      }
    };

    fetchDocVerificationStatus(); // Call the fetch function when the component mounts
  }, [row?.organization?._id]); // Only re-run the effect if the organization ID changes

  const actionOrg = async (e, action) => {
    e.stopPropagation();
    try {
      let newStatus;

      switch (action) {
        case "approve":
          newStatus = "approved";
          await putCall(
            `/api/v1/organizations/${row?.organization?._id}/enable`,
            { documentApprovalStatus: newStatus }
          );
          break;
        case "reject":
          newStatus = "rejected";
          await putCall(
            `/api/v1/organizations/${row?.organization?._id}/enable`,
            { documentApprovalStatus: newStatus }
          );
          break;
        default:
          break;
      }


      setDocApprovalStatus(newStatus);

      setAnchorEl(null);

      if (view === "admin") {
        getAdmins();
      } else {
        getProviders();
      }
    } catch (error) {
      console.log(error.response);
    }
  };


  return (
    <div>
      <Tooltip title="Action">
        <Button
          id="user-action-menu-button"
          aria-controls={Boolean(anchorEl) ? "user-action-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={Boolean(anchorEl) ? "true" : undefined}
          onClick={openActionMenu}
          sx={{ width: 30 }}
        >
          <StyledMoreVertIcon />
        </Button>
      </Tooltip>
      <Menu
        id="user-action-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          onMouseLeave: handleClose,
          "aria-labelledby": "user-action-menu-button",
        }}
      >
        {isProvider && (
          <MenuItem
            onClick={() =>
              navigate(
                `/profile/${row?.organization?._id}`
              )
            }
          >
            View
          </MenuItem>
        )}
        <MenuItem onClick={(e) => action(e, "enable")}>Mark as Active</MenuItem>
        <MenuItem onClick={(e) => action(e, "disable")}>
          Mark as Inactive
        </MenuItem>
        {docApprovalStatus !== "approved" && (
          <>
            <MenuItem onClick={(e) => actionOrg(e, "approve")}>
              Approve Documents
            </MenuItem>
            <MenuItem onClick={(e) => actionOrg(e, "reject")}>
              Reject Documents
            </MenuItem>
          </>
        )}
        {row.bannedUser && (
          <MenuItem onClick={(e) => action(e, "unlock")}>Unlock user</MenuItem>
        )}
      </Menu>
      <div key="documentStatus"
        style={{
          borderBottom: docApprovalStatus==='approved'?'2px solid green':'2px solid red',
          maxWidth: '5rem',
          margin: '0 auto',
          padding: '4px 0',
          textTransform: 'capitalize'
        }}>
        {docApprovalStatus}
      </div>
    </div>
  );
};

const UserTable = (props) => {
  const {
    getProviders,
    getAdmins,
    columns,
    data,
    isProvider,
    page,
    rowsPerPage,
    totalRecords,
    handlePageChange,
    handleRowsPerPageChange,
    view,
  } = props;
  const theme = useTheme();
  const navigate = useNavigate();
  const [docApprovalStatus, setDocApprovalStatus] = useState("");
  const onPageChange = (event, newPage) => {
    handlePageChange(newPage);
  };

  const onRowsPerPageChange = (event) => {
    handleRowsPerPageChange(parseInt(event.target.value, 10));
    handlePageChange(0);
  };
  useEffect(() => {
    // Fetch document approval status when the component mounts
    const fetchDocVerificationStatus = async () => {
      try {
        const url = `/api/v1/organizations/${data?.organization?._id}`;
        const data1 = await getCall(url);
        console.log("Data", data1);
        console.log("Provider detail", data1.providerDetail);

        const approval = data1.providerDetail?.documentApprovalStatus;

        console.log("Approval", approval);
        setDocApprovalStatus(approval);
      } catch (error) {
        console.error("Error fetching document verification status:", error);
        setDocApprovalStatus("error");
      }
    };
    fetchDocVerificationStatus(); // Call the fetch function when the component mounts
  }, [data?.organization?._id]); // Only re-run the effect if the organization ID changes
  const getStatusColor = (status) => {
    switch (status) {
      case "approved":
        return "green";
      case "rejected":
        return "red";
      case "pending":
        return "orange";
      default:
        return "black"; // You can set a default color or handle other cases
    }
  };
  const fetchDataAndRenderStatus = async (organizationId) => {
    try {
      const url = `/api/v1/organizations/${organizationId}`;
      const data = await getCall(url);

      const approvalStatus = data.providerDetail?.documentApprovalStatus;

      return (
        <span style={{ color: getStatusColor(approvalStatus) }}>
          {approvalStatus}
        </span>
      );
    } catch (error) {
      console.error("Error fetching document verification status:", error);
      return <span style={{ color: "red" }}>Error</span>;
    }
  };

  columns.map((column)=>column.align="center")

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            {columns.map((column) => (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                }}
              >
                {column.label}
              </StyledTableCell>
            ))}
          </TableHead>
          <TableBody>
            {data.map((row, index) => {
              return (
                <TableRow
                  // style={{ cursor: isProvider ? "pointer" : "default" }}
                  hover
                  tabIndex={-1}
                  key={index}
                >
                  {columns.map((column) => {
                    const value = row[column.id];
                    if (column.id == "Action") {
                      const user_id = localStorage.getItem("user_id");
                      if (props.isProvider) {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <ThreeDotsMenu
                              view={view}
                              row={row}
                              isProvider={isProvider}
                              getAdmins={getAdmins}
                              getProviders={getProviders}
                              docApprovalStatus={docApprovalStatus}
                              setDocApprovalStatus={setDocApprovalStatus}
                            />
                          </TableCell>
                        );
                      } else {
                        if (user_id === row._id) {
                          return <></>;
                        } else {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              <ThreeDotsMenu
                                view={view}
                                row={row}
                                isProvider={isProvider}
                                getAdmins={getAdmins}
                                getProviders={getProviders}
                                docApprovalStatus={docApprovalStatus}
                                setDocApprovalStatus={setDocApprovalStatus}
                              />
                            </TableCell>
                          );
                        }
                      }
                    } else if (column.id == "formatted_status") {
                      return (
                        <TableCell key={column.id} align={column.align}>
                          <Stack direction="row" justifyContent={column.align} alignItems="center" spacing={2}>
                            <span>{value}</span>
                            {row.bannedUser && (
                              <LockOutlined sx={{ color: "red" }} />
                            )}
                          </Stack>
                        </TableCell>
                      );
                    } else if (column.id == "providerName") {
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {row?.organization?.name}
                        </TableCell>
                      );
                    }

                    return (
                      <TableCell key={column.id} align={column.align}>
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        ActionsComponent={TableActionsComponent}
      />
    </Paper>
  );
};

export default UserTable;
