import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import RenderInput from "../../utils/RenderInput";
import {
  isValidBankAccountNumber,
  isValidIFSC,
  isNameValid,
  isEmailValid,
  isValidPAN,
  isPhoneNoValid,
  isValidAadhar,
  isValidGSTIN,
} from "../../utils/validations";
import { postCall, getCall, putCall } from "../../Api/axios";
import { deleteAllCookies } from "../../utils/cookies";
import cogoToast from "cogo-toast";
import { useNavigate, useParams } from "react-router-dom";
import { containsOnlyNumbers } from "../../utils/formatting/string";
import useForm from "../../hooks/useForm";
import userFields from "./provider-user-fields-modified";
import kycDetailFields from "./provider-key-fields-modified";
import kycDocumentFields from "./provider-kyc-doc-fields-modified";
import bankDetailFields from "./provider-bank-details-fields";
// import {
//   loadCaptchaEnginge,
//   LoadCanvasTemplate,
//   validateCaptcha,
// } from "react-simple-captcha";

const UpdateProvider = () => {
  const params = useParams();
  const handleChange = (id, value) => {
    if (id === "GSTN") {
      const uppercaseGSTN = value.toUpperCase();
      setFormValues((prevValues) => ({
        ...prevValues,
        [id]: uppercaseGSTN,
      }));
    }
  };
  const navigate = useNavigate();
  const [step, setStep] = useState(2);
  const [user, setUserDetails] = useState({});

  const [kycDetails, setKycDetails] = useState({});
  //   const [tncDetails, setTncDetails] = useState({
  //     tnc: false,
  //   });

  const [kycMedia, setkycMedia] = useState({});

  const [bankDetails, setBankDetails] = useState({});

  const { formValues, setFormValues, errors, setErrors } = useForm({
    ...user,
    ...kycDetails,
    ...kycMedia,
    ...bankDetails,
  });

  useEffect(() => {
    let provider_id = params?.id;
    getOrgDetails(provider_id);
  }, [params.id]);
  const getOrgDetails = async (id) => {
    try {
      const url = `/api/v1/organizations/${id}`;
      const res = await getCall(url);
      console.log(res);
      console.log("Fetched Data");

      setFormValues({
        email: res.user.email,
        mobile: res.user.mobile,
        providerStoreName: res.providerDetail.name,
        GSTN: res?.providerDetail?.GSTN.GSTN,
        GST_proof: constructProofUrl(res?.providerDetail?.GSTN.proof.path),
        name: res.user.name,
        PAN: res?.providerDetail?.PAN.PAN,
        aadhar: res?.providerDetail?.aadhar.aadhar,
        aadhar_proof: constructProofUrl(res?.providerDetail?.idProof.path),
        accHolderName: res?.providerDetail?.bankDetails?.accHolderName,
        accNumber: res?.providerDetail?.bankDetails?.accNumber,
        bankName: res?.providerDetail?.bankDetails?.bankName,
        branchName: res?.providerDetail?.bankDetails?.branchName,
        IFSC: res?.providerDetail?.bankDetails?.IFSC,
        cancelledCheque: constructProofUrl(
          res?.providerDetail?.bankDetails?.cancelledCheque?.path
        ),
        // captcha: "",
      });
      function constructProofUrl(originalUrl) {
        if (!originalUrl) {
          return undefined;
        }
        let myBucket = process.env.REACT_APP_S3_BUCKET;
        let regionString = '-' + process.env.REACT_APP_S3_REGION;
        const baseUrl = `https://${myBucket}.s3${regionString}.amazonaws.com`;

        // Remove the base URL if it's already present
        const modifiedImagePath = originalUrl.replace(new RegExp(`^${baseUrl}/`), '');

        // Replacing characters in the image path
        const modifiedUrl = modifiedImagePath
          .replace(/\+/g, "%2B")
          .replace(/ /g, "+");

        // Constructing the new URL
        const newUrl = `${baseUrl}/${modifiedUrl}`;

        return newUrl;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [formSubmitted, setFormSubmited] = useState(false);

  const handleContinue = () => {
    console.log("next Step");
    setStep(step + 1);
    setFormSubmited(false);
  };

  // useEffect(() => {
  //   if (step === 5) {
  //     console.log("alert");
  //     loadCaptchaEnginge(6);
  //   }
  // }, [step]);

  const sendInvite = async () => {
    setFormSubmited(true);
    try {
      let myBucket = process.env.REACT_APP_S3_BUCKET;
      let regionString = '-' + process.env.REACT_APP_S3_REGION;
      let burl = `https://${myBucket}.s3${regionString}.amazonaws.com`;
      const baseUrl = burl;
      const baseUrls = [
        baseUrl

      ]
      const cleanS3Url = (url, baseUrls) => {
        let cleanedUrl = url;
        baseUrls.forEach(baseUrl => {
            cleanedUrl = cleanedUrl.replace(baseUrl, "");
        });
        return cleanedUrl;
    };
      const data = {
        user: {
          name: formValues.name,
          email: formValues.email,
          mobile: formValues.mobile,
          password: formValues.password,
          documentApprovalStatus: formValues.documentApprovalStatus,
        },
        providerDetails: {
          name: formValues.providerStoreName,
          // address: formValues.address,
          // contactEmail: formValues.contactEmail,
          // contactMobile: formValues.contactMobile,
          // addressProof: formValues.address_proof,
          idProof: cleanS3Url(formValues.aadhar_proof,baseUrls),
          bankDetails: {
            accHolderName: formValues.accHolderName,
            accNumber: formValues.accNumber,
            IFSC: formValues.IFSC,
            cancelledCheque: cleanS3Url(formValues.cancelledCheque,baseUrls),
            bankName: formValues.bankName,
            branchName: formValues.branchName,
          },
          aadhar: { aadhar: formValues.aadhar },
          PAN: { PAN: formValues.PAN, proof: formValues.PAN_proof },
          GSTN: { GSTN: formValues.GSTN, proof: cleanS3Url(formValues.GST_proof,baseUrls) },
          // FSSAI: formValues.FSSAI,
        },
      };
      const orgId = params?.id;
      const url = `/api/v1/organizations/${orgId}/update`;

      const url2 = await putCall(`/api/v1/organizations/${orgId}/enable`, {
        documentApprovalStatus: "pending",
      });
      console.log("url2", url2);

      const res = await postCall(url, data);
      setFormSubmited(false);
      await postCall(`/api/v1/auth/logout`);
      deleteAllCookies();
      localStorage.clear();
      sessionStorage.clear();
      navigate("/");
      cogoToast.success("Document Updated successfully");
    } catch (error) {
      console.log("error.response", error.response);
      cogoToast.error(error.response.data.error);
    }
  };

  // const checkDisabled = () => {
  //   if (user.email == "" || !isEmailValid(user.email)) return true;
  //   if (user.password == "" || !isPhoneNoValid(user.mobile_number)) return true;
  //   if (user.provider_admin_name.trim() == "") return true;
  //   return false;
  // };

  const renderHeading = () => {
    if (step == 2) return "Business Details"; // "User Details";
    if (step == 3) return "GST Details";
    if (step == 4) return "Bank Account Details";
    if (step == 5) return "Seller KYC Details";
  };
  const renderSubHeading = () => {
    if (step === 4) return "Bank account should be in the name of registered business name or trade name as per GSTIN.";
    if (step === 5) return "Enter the Business Owner’s Details.";
  };

  const renderFormFields = (fields) => {
    return fields.map((item) => (
      <RenderInput
        item={{
          ...item,
          error: !!errors?.[item.id],
          helperText: errors?.[item.id] || "",
          readOnly: step === 2,
        }}
        state={formValues}
        stateHandler={setFormValues}
      />
    ));
  };

  const renderSteps = () => {
    if (step == 2) return renderFormFields(userFields);
    if (step == 3) return renderFormFields(kycDetailFields);
    if (step == 4) return renderFormFields(bankDetailFields);
    if (step == 5) return renderFormFields(kycDocumentFields);
  };

  const handleBack = async () => {
    if (step === 2) {
      try {
        // Attempt to log out
        await postCall(`/api/v1/auth/logout`);

        // Clear cookies and local storage
        deleteAllCookies();
        localStorage.clear();
        sessionStorage.clear();

        // Show a success message
        cogoToast.success("Logout successful");

        // Navigate to the home page
        navigate("/");
      } catch (error) {
        // Handle any errors that might occur during logout
        console.error("Logout failed:", error);
        cogoToast.error("Logout failed");
      }
    } else {
      setStep(step - 1);
    }
  };

  const validate = () => {
    const formErrors = {};
    if (step === 2) {
      formErrors.email =
        formValues.email.trim() === ""
          ? "Email is required"
          : !isEmailValid(formValues.email)
            ? "Please enter a valid email address"
            : "";
      formErrors.mobile =
        formValues.mobile.trim() === ""
          ? "Mobile Number is required"
          : !isPhoneNoValid(formValues.mobile)
            ? "Please enter a valid mobile number"
            : "";
      formErrors.providerStoreName =
        formValues.providerStoreName.trim() === ""
          ? "Business Name is required"
          : "";
    } else if (step === 3) {
      // formErrors.address =
      //   formValues.address.trim() === ""
      //     ? "Registered Address is required"
      //     : "";
      formErrors.GSTN =
        formValues.GSTN.trim() === ""
          ? "GSTIN Certificate is required"
          : !isValidGSTIN(formValues.GSTN)
            ? "Please enter a valid GSTIN."
            : "";
      formErrors.GST_proof =
        formValues.GST_proof.trim() === ""
          ? "GSTIN Certificate is required"
          : "";
      // formErrors.FSSAI =
      //   formValues.FSSAI.trim() === ""
      //     ? "FSSAI Number is required"
      //     : !isValidFSSAI(formValues.FSSAI) || formValues.FSSAI.length !== 14
      //     ? "FSSAI should be 14 digit number"
      //     : "";
    } else if (step === 4) {
      formErrors.accHolderName =
        formValues.accHolderName.trim() === ""
          ? "Account Holder Name is required"
          : !isNameValid(formValues.accHolderName)
            ? "Please enter a valid account holder name"
            : "";
      formErrors.accNumber =
        formValues.accNumber.trim() === ""
          ? "Account Number is required"
          : !isValidBankAccountNumber(formValues.accNumber)
            ? "Please enter a valid number"
            : "";
      formErrors.bankName =
        formValues.bankName.trim() === "" ? "Bank Name is required" : "";
      formErrors.branchName =
        formValues.branchName.trim() === "" ? "Branch Name is required" : "";
      formErrors.IFSC =
        formValues.IFSC.trim() === ""
          ? "IFSC Code is required"
          : !isValidIFSC(formValues.IFSC)
            ? "Please enter a valid IFSC Code"
            : "";
      formErrors.cancelledCheque =
        formValues.cancelledCheque.trim() === ""
          ? "Bank Passbook / Cancelled Cheque is required"
          : "";
    } else if (step === 5) {
      formErrors.name =
        formValues.name.trim() === ""
          ? "Name is required"
          : !isNameValid(formValues.name)
            ? "Please enter a valid Name"
            : "";
      // formErrors.address_proof =
      //   formValues.address_proof.trim() === ""
      //     ? "Address Proof is required"
      //     : "";
      formErrors.aadhar =
        formValues.aadhar.trim() === ""
          ? "Aadhar is required"
          : !isValidAadhar(formValues.aadhar)
            ? "Please enter a valid Aadhar number"
            : "";
      formErrors.aadhar_proof =
        formValues.aadhar_proof.trim() === "" ? "Aadhar Card is required" : "";
      formErrors.PAN =
        formValues.PAN.trim() === ""
          ? "PAN is required"
          : !isValidPAN(formValues.PAN)
            ? "Please enter a valid PAN number"
            : "";
      // formErrors.PAN_proof =
      //   formValues.PAN_proof.trim() === "" ? "PAN Card Image is required" : "";
      // formErrors.captcha =
      //   formValues.captcha.trim() === ""
      //     ? "Captcha is required"
      //     : !validateCaptcha(formValues.captcha)
      //       ? "Captcha does not match"
      //       : "";
    }
    setErrors({
      ...formErrors,
    });
    return !Object.values(formErrors).some((val) => val !== "");
  };

  const handleSubmit = () => {
    if (validate()) {
      step === 5 ? sendInvite() : handleContinue();
    }
  };

  // useEffect(() => {
  //   if (!formSubmitted) return
  //   validate()
  // }, [formValues])

  console.log("formValues====>", formValues);
  return (
    <div
      className="mx-auto !p-5 h-screen min-vh-100 overflow-auto bg-[#f0f0f0]"
      style={{ height: "100%", marginTop: "10px" }}
    >
      <div className="h-full flex fex-row items-center justify-center">
        <div
          className="flex w-full md:w-2/4 bg-white px-4 py-4 rounded-md shadow-xl h-max"
          style={{ boxShadow: "-2px 10px 20px -1px rgba(237, 60, 173, 0.4)" }}
        >
          <div className="m-auto w-10/12 md:w-3/4 h-max">
            <form>
              <p className="text-2xl font-semibold mb-4 text-center">
                {renderHeading()}
              </p>
              <p className="text-2x1 mb-4 text-center">{renderSubHeading()}</p>
              <div>
                {renderSteps()}
                {step === 5 ? (
                  <>
                    {/* <div className="py-1">
                      <LoadCanvasTemplate />
                    </div>
                    <div className="py-1">
                      <RenderInput
                        item={{
                          id: "captcha",
                          // title: "Serviceable Radius/Circle (in Kilometer)",
                          placeholder: "Enter Captcha Value",
                          type: "input",
                          error: errors?.["captcha"] ? true : false,
                          helperText: errors?.["captcha"] || "",
                        }}
                        state={formValues}
                        stateHandler={setFormValues}
                      />
                    </div> */}
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="flex mt-6">
                <Button
                  type="button"
                  size="small"
                  style={{
                    marginRight: 10,
                    backgroundColor: "#1c75bc",
                    color: "#ffff",
                    boxShadow: "-2px 10px 20px -1px rgba(237, 60, 173, 0.4)",
                  }}
                  variant="text"
                  onClick={handleBack}
                  disabled={formSubmitted}
                >
                  {step === 2 ? "Logout" : "Back"}
                </Button>
                <Button
                  type="button"
                  size="small"
                  style={{
                    backgroundColor: "#1c75bc",
                    boxShadow: "-2px 10px 20px -1px rgba(237, 60, 173, 0.4)",
                  }}
                  disabled={formSubmitted}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                //  disabled={checkDisabled()}
                >
                  {step == 5 ? "Update" : step === 1 ? "I Agree" : "Continue"}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateProvider;
